<template>
    <page title="学生考勤" class="student-attendance-page" :hasBack="true" textAlign="left">
        <div class="search-wrapper">
            <form action="/"  class="search">
                <van-search @search="search"  @cancel="search" v-model="searchValue" placeholder="请输入学生姓名查询吧..." />
            </form>
            <div class="choose-item" @click="changeClassPickVisible">
                <span v-if="!currentChooseClass.id">请选择班级</span>
                <span v-else>{{currentChooseClass.name}}</span>
                <img src="@/assets/images/common/down.png" alt="" />
            </div>
        </div>
        <van-popup  v-model:show="classPickerVisible" round position="bottom">
            <van-picker
                title="选择学生"
                :default-index="currentChooseClass.id ?  classList.value?.findIndex(item => item.id == currentChooseClass.id) : 0"
                :columns="classList"
                @confirm="classConfirm"
                @cancel="changeClassPickVisible"
            />
        </van-popup>
        <van-calendar
            :show-confirm="false"
            :show-title="false"
            :poppable="false"
            @confirm="dateConfirm"
            :min-date="minDate"
            :style="{ height: '345px' }"
        />
        <common-block class="attendance-list" title="上午考勤" :border="false">
            <attendance-list :attendanceHasLoad="attendanceHasLoad"  :attendanceList="attendanceListAm" />
        </common-block>
        <common-block class="attendance-list" title="下午考勤" :border="false">
            <attendance-list :attendanceHasLoad="attendanceHasLoad" :attendanceList="attendanceListPm" />
        </common-block>
        <common-block class="attendance-list" title="晚上考勤" :border="false">
            <attendance-list :attendanceHasLoad="attendanceHasLoad" :attendanceList="attendanceListGn" />
        </common-block>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import CommonBlock from '@/components/commonBlock/CommonBlock.vue';
import AttendanceList from './components/AttendanceList';
import commonRequest from '@/services';
import { Calendar, Search, Picker, Popup } from 'vant';
import { reactive, ref } from 'vue';
import {getStorage, setStorage} from "@/utils/storage";
import dayjs from "dayjs";
import {DATE_FORMATE} from "@/constants";
export default {
    name: 'schoolNotice',
    components: {
        [Page.name]: Page,
        [Calendar.name]: Calendar,
        [AttendanceList.name]: AttendanceList,
        [CommonBlock.name]: CommonBlock,
        [Search.name]: Search,
        [Picker.name]: Picker,
        [Popup.name]: Popup,

    },
    setup() {
        const chooseDate = ref(new Date());
        const minDate = ref(new Date('1970-01-01'));
        const attendanceListAm = ref([]);
        const attendanceListPm = ref([]);
        const attendanceListGn = ref([]);
        const dateConfirm = (value) => {
            chooseDate.value = value;
            fetchAttendanceList();
            attendanceListAm.value = [];
            attendanceListPm.value = [];
            attendanceListGn.value = [];
        };
        const stepList = reactive([
            { title: '进入校门', content: '2021-10-01 07:18:15', id: 1 },
            { title: '进入校门', content: '2021-10-01 07:18:15', id: 2 },
            { title: '进入校门', content: '2021-10-01 07:18:15', id: 3 },
        ]);
        const stepListHasLoad = ref(true);
        const searchValue = ref('');
        const currentChooseClass = reactive({
            id: '',
            name: ''
        });

        const classList = ref([]);
        const classPickerVisible = ref(false);
        const attendanceHasLoad = ref(false);
        const fetchAttendanceList = () => {
            attendanceHasLoad.value = false;
            // dateTime
            commonRequest({ action: 12006, classID: currentChooseClass.id, studentName: searchValue.value, dateTime: dayjs(chooseDate.value).format(DATE_FORMATE)}).then((res) => {
                attendanceHasLoad.value = true;
                if(res.data) {
                    const amItem = res.data.find(item => item.name === '上午考勤');
                    if (amItem) {
                        attendanceListAm.value = amItem.students;
                    } else {
                        attendanceListAm.value = [];
                    }
                    const pmItem = res.data.find(item => item.name === '下午考勤');
                    if (pmItem) {
                        attendanceListPm.value = pmItem.students;
                    } else {
                        attendanceListPm.value = [];
                    }
                    const gnItem = res.data.find(item => item.name === '晚上考勤');
                    if (gnItem) {
                        attendanceListGn.value = gnItem.students;
                    } else {
                        attendanceListGn.value = [];
                    }
                }
            })
        };
        const changeClassPickVisible = () => {
            classPickerVisible.value = !classPickerVisible.value
        };
        commonRequest({ action: 12009 }).then((res) => {
            if (res.data) {
                classList.value = res.data.map(item => ({
                    id: item.classId,
                    text: item.className
                }));
                if (res.data.length) {
                    const chooseStudentId = getStorage('chooseClassId');
                    if (!chooseStudentId) {
                        currentChooseClass.id = res.data[0].classId;
                        currentChooseClass.name = res.data[0].className;
                        setStorage('chooseClassId', res.data[0].classId);
                    } else {
                        currentChooseClass.id = chooseStudentId;
                        currentChooseClass.name = res.data.find(item => item.classId == chooseStudentId)?.className;
                    }
                }
                if (res.data.length) {
                    fetchAttendanceList()
                }
            }
        });
        const classConfirm = (value) => {
            currentChooseClass.id = value.id;
            changeClassPickVisible();
            fetchAttendanceList()
        };
        const search = () => {
            fetchAttendanceList();
        }
        return {
            dateConfirm,
            stepList,
            stepListHasLoad,
            searchValue,
            chooseDate,
            classList,
            classPickerVisible,
            changeClassPickVisible,
            currentChooseClass,
            classConfirm,
            search,
            attendanceListAm,
            attendanceListPm,
            attendanceListGn,
            attendanceHasLoad,
            minDate
        };
    },
};
</script>
<style lang="less" scoped>
.student-attendance-page {
    padding-bottom: 20px;
    box-sizing: border-box;
    /deep/ .common-inner {
        height: 100%;
    }
}
.attendance-list {
    height: 295px;
    display: flex;
    flex-direction: column;
    /deep/ .common-content {
        flex: 1;
        overflow: auto;
    }
}
.search-wrapper {
    display: flex;
    align-items: center;
    .search {
        flex: 1;
    }
    .choose-item {
        flex-shrink: 0;
        margin-left: 16px;
        display: flex;
        align-items: center;
        span {
            font-size: 14px;
            font-family: PingFang SC, serif;
            color: #333333;
        }
        img {
            width: 8px;
            height: 5px;
            margin-left: 3px;
        }
    }

}
</style>

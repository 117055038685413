<template>
    <div class="list-page">
        <template v-if="!attendanceHasLoad || attendanceList.length">
            <div class="item" v-for="(item, index) in attendanceList" :key="index">
                <div class="title">{{ item.studentName }}</div>
                <div class="time">
                    <span class="label">进校时间:</span>
                    <span>{{ item.enterTime }}</span>
                </div>
                <div class="time">
                    <span class="label">出校时间:</span>
                    <span>{{ item.outTime }}</span>
                </div>
            </div>
        </template>
        <empty  v-else />
    </div>
</template>
<script>
import Empty from '@/components/empty/Empty';
import { List } from 'vant';
export default {
    name: 'AttendanceList',
    components: {
        [Empty.name]: Empty,
        [List.name]: List,
    },
    props: {
        attendanceList: {
            type: Array,
            required: true,
        },
        attendanceHasLoad: Boolean
    }
};
</script>
<style lang="less" scoped>
.list-page {
    height: 100%;
}

.attendance-list {
    /*padding: 4px 0;*/
    height: 100%;
    margin-top: -10px;
    .item {
        padding: 10px 0;
        border-bottom: 1px solid #f0f0f0;
        &:nth-last-of-type(1) {
            border-bottom: none;
        }
        .title {
            font-size: 15px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            line-height: 17px;
            color: #333333;
        }
        .time {
            margin-top: 5px;
            span {
                font-size: 12px;
                font-family: PingFang SC, serif;
                line-height: 17px;
                color: #666666;
            }
            .label {
                color: #333333;
                margin-right: 5px;
            }
        }
    }
}
</style>
